.bagItemsCount {
  font-family:
    ModerneRegular,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  display: inline-block;
  font-size: var(--description-size);
  text-align: center;
  vertical-align: -webkit-baseline-middle;
  color: var(--color-black);
  transform: translate(0, 4px);
}
.bagLink {
  background: url('/assets-new/shop-trigger.svg') center center no-repeat;
  background-size: 24px;
  height: 24px;
  text-align: center;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bagLink:not(.emptyBag):hover {
  background: url('/assets-new/shop-trigger-active.svg') center center no-repeat;
  background-size: 24px;
  height: 24px;
  text-align: center;
  width: 24px;
}

.bagLink:not(.emptyBag):hover > .bagItemsCount {
  color: var(--color-white);
}
.promoLabel {
  margin-bottom: 0px;
  color: var(--color-success);
  font-size: var(--description-size);
  text-align: center;
}
.promoLabelObtained {
  margin-bottom: 0px;
  color: var(--color-success);
  font-size: var(--description-size);
  text-align: center;
}
/* Hemos quitado los cambios de iconos de NNORMAL que venían de la rama de apparel size guide
porque causaba que se viese mal en producción, a la hora de recuperarlos deberían estar en la rama de iconos
*/

@media (min-width: 992px) {
  .bagLink {
    grid-column: 6;
    background-size: 16px auto;
    width: 18px;
    background-position: 50% 20%;
  }
  .bagLink:not(.emptyBag):hover {
    background: url('/assets-new/shop-trigger-active.svg') 50% 20% no-repeat;
    grid-column: 6;
    background-size: 16px auto;
    width: 18px;
  }
  .bagItemsCount {
    font-size: var(--xsmall-size);
    transform: translate(0, 0);
    position: relative;
  }
}
.productItem {
  padding: 10px 0;
  display: grid;
}
body[data-domain='camperlab'] .productItem {
  padding: 5px 0px;
}
.menuItem {
  grid-gap: 20px;
  grid-template-columns: 80px 1fr auto;
  display: grid;
  font-size: var(--regular-size);
  padding-right: 1em;
}

body[data-domain='camperlab'] .menuItem {
  grid-gap: 7px;
  grid-template-columns: 30% 50% 16%;
  display: grid;
  font-size: var(--regular-size);
  padding-right: 0px;
}

.productInfo {
  margin: auto;
  margin-left: 0;
}
body[data-domain='camperlab'] .productInfo {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

body[data-domain='nnormal'] .productInfo {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 11rem;
}

body[data-domain='nnormal'] .productInfo p.productInfoName {
  font-family: var(--font-secondary);
  margin-top: 0;
}

.productInfo p {
  margin-bottom: 0px;
  display: flex;
}
body[data-domain='nnormal'] .productInfo p {
  margin-top: 0;
  color: var(--color-black);
  font-size: 0.65rem;
}
.productInfo p:first-child {
  line-height: 1.2;
  font-size: var(--description-size);
}
body[data-domain='nnormal'] .productInfo p:first-child {
  font-size: 0.875em;
  font-family: var(--font-secondary);
  font-weight: 600;
  /* margin-top: 8px; */
}

.productInfo p:nth-child(2) {
  font-size: var(--xsmall-size);
  line-height: 1.7;
  color: var(--color-disabled-primary);
}

.productInfo p:nth-child(3) {
  margin-top: 4px;
  font-size: var(--small-size);
  line-height: 1;
}

.productInfoPrice {
  margin-top: 16px;
  font-size: var(--small-size);
  line-height: 1;
}

.itemDiscount {
  color: var(--color-disabled-primary);
  margin-right: 5px;
}

body[data-domain='camperlab'] .itemDiscount {
  margin-right: 0px;
  margin-left: 5px;
}
.discountByVoucher {
  color: var(--color-success);
}
.productInfo p.productDescription {
  margin-bottom: 0;
  color: var(--color-black);
  font-size: 0.65rem;
  text-wrap: wrap;
  line-height: 1;
}
.size {
  margin-right: 2.5em;
  text-transform: uppercase;
}
.bagMenu {
  padding: 16px 0px 10px 8px;
  overflow: auto;
  min-width: 360px;
  width: clamp(300px, 360px, 400px);
  display: block;
  position: absolute;
  top: calc(100% - 16px);
  right: 0;
  z-index: 10000;
  font-family: 'ModerneRegular';
  max-width: 90vw;
}
body[data-domain='nnormal'] .bagMenu {
  top: calc(100% - 20px);
}

body[data-domain='camperlab'] .emptyMenu {
  min-width: 360px;
}

body[data-domain='camperlab'] .bagOverlay {
  padding-right: 16px;
}

.bagOverlay {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 16px;
  background-color: var(--color-white);
  text-align: left;
  padding-right: 0px;
}
.bagIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bagList {
  padding-inline-start: 0px;
  margin-bottom: 0px;
}
.emptyMenu {
  width: 300px;
  height: 300px;
  min-width: 300px;
}

.emptyMenu p {
  font-size: 22px;
  line-height: 30px;
}
.productImg {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: bottom;
}
.btnPurchase {
  background-color: var(--color-white);
  position: sticky;
  bottom: 0;
  padding-top: 0.5em;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-top: 1px solid var(--color-divider);
  display: block;
  margin-right: 16px;
}

body[data-domain='camperlab'] .spinWrapper :global .ant-spin-nested-loading,
body[data-domain='camperlab'] .spinWrapper :global .ant-spin-container {
  height: 100%;
}

body[data-domain='camperlab'] .spinWrapper {
  height: 100%;
}

.totalPrice {
  font-size: var(--title-size) !important;
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 2px;
  font-weight: 600;
}

body[data-domain='camperlab'] .totalPrice {
  font-size: 1.313rem !important;
  text-transform: uppercase;
}

.taxes {
  font-size: 0.625em !important;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: var(--description-size);
  margin-bottom: 15px;
}
.taxesPrices {
  color: var(--color-success);
  margin-left: 5px;
}
.btnGoPurchase > p {
  padding: 10px 20px;
  height: auto;
  margin-bottom: 0;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
}
body[data-domain='nnormal'] .btnGoPurchase > p {
  font-family: var(--font-secondary);
}
.btnGoPurchase:hover > p {
  border-color: var(--color-white);
  background-color: var(--color-white);
  color: var(--color-black);
}
.removeProduct {
  align-self: flex-end;
  align-self: center;
  color: var(--color-black);
  cursor: pointer;
}
.removeProduct:hover {
  color: var(--color-black);
  opacity: 0.7;
}

body[data-domain='camperlab'] .removeProduct {
  border-bottom: 1px solid black;
  margin-top: 0.5em;
  align-self: flex-start;
  line-height: 14px;
  font-size: 12px;
}

.emptyProducts {
  cursor: auto;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 140px;
  padding: 10px 20px 10px 20px;
  margin-bottom: 0;
}

/* TODO: quitar cuando este en prod el plugin de tailwind para nnormal */
body[data-domain='nnormal'] .menuItemTitle {
  font-family: var(--font-secondary);
}

.tooltip :global .ant-tooltip-content {
  display: flex;
  justify-content: flex-end;
}

.tooltip :global .ant-tooltip-inner {
  background-color: #dfdfdf;
  color: black;
  min-height: 17px;
  font-size: var(--description-size);
  width: 40%;
}
.tooltip :global .ant-tooltip-arrow {
  left: 87%;
}

.tooltip :global .ant-tooltip-arrow-content {
  background-color: #dfdfdf;
  width: 10px;
  height: 10px;
}

.itemsWrapper {
  overflow-y: auto;
  margin-right: 16px;
  max-height: 12.5em;
}

body[data-domain='camperlab'] .itemsWrapper {
  max-height: none;
  margin-right: 0px;
}

.collectionButton {
  padding: 10px 20px;
  color: var(--color-white);
  background-color: var(--color-black);
  border-color: var(--color-black);
  margin: 2%;
  text-align: center;
  width: 120px;
  height: 40px;
  font-size: 16px;
}

.collectionButton:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

body[data-domain='camperlab'] .collectionButton {
  min-width: 140px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
}

.btnContinueShopping {
  padding: 10px 20px;
  height: auto;
  text-align: center;
  margin-top: 1em;
  border: 1px solid var(--color-black);
  background-color: var(--color-white);
  color: var(--color-black);
}

.btnContinueShopping p {
  margin-bottom: 0px;
}

body[data-domain='camperlab'] .bagOverlay > div {
  border-bottom-width: 0px;
}

body[data-domain='camperlab'] .taxes {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: bold;
}

body[data-domain='camperlab'] .taxesPrices {
  color: var(--color-black);
  border-bottom: 1px solid black;
  margin-left: 5px;
  text-transform: none;
}

@media (max-width: 767.5px) {
  .bagMenu {
    min-width: 100%;
  }

  body[data-domain='camperlab'] .bagMenu {
    padding: 0px;
    top: 0px;
    position: fixed;
    height: 100vh;
  }

  body[data-domain='camperlab'] .bagOverlay {
    height: 100%;
  }

  body[data-domain='camperlab'] .menuItemTitle {
    display: none;
  }

  .emptyMenu {
    min-width: 300px;
  }
  .itemsWrapper {
    overflow-y: auto;
    max-height: 9.5em;
  }
}

@media screen and (max-width: 991px) {
  .tooltip :global .ant-tooltip-arrow {
    left: 86%;
  }
}

@media screen and (max-width: 1200px) {
  .emptyMenu p {
    text-align: left;
  }

  body[data-domain='camperlab'] .bagMenu {
    right: unset;
    left: 0;
    padding: 0px;
    top: calc(100% + 16px);
  }
}

.noScroll {
  overflow-y: hidden;
  max-height: 100%;
}
